import React,{useState, useContext} from 'react'
import {BrowserRouter as Router, Switch, Link, Route} from 'react-router-dom'
import Logo from '../images/logotext.png'
import { AuthContext } from "../App"
import {LoginKnap} from "./Utility/Knapper";

export default function Header(props) {
    return (
        <header>
            <img className={'logo'}src={Logo} alt=""/>

        </header>
    )
}
