import React, {useEffect, useState} from 'react';
import {DefaultKnap, SkjulKnap} from "./Utility/Knapper";
import lochness_front from "../images/smaaBilleder/loch_ness_front.png"
import lochness_back from "../images/smaaBilleder/loch_ness_back.png"
import {CloseOutside} from "./Utility/CloseOutside";

function PayWall({paywallState, setPaywallState, modal}) {

    if (modal) {
        return (<div className="paywall lukket"/>)
    }

    function lukPaywall() {
            if (paywallState.state == '') {
                setPaywallState({...paywallState, state: "aaben"})
            } else {
                setPaywallState({data: null, state: ""})
            }
    }

    return (
        <>
            <div className={"paywall " + paywallState.state}>
                <div id="lukkeomraade" onClick={ () => lukPaywall()}>
                    <SkjulKnap state={paywallState.state} closure={() => lukPaywall() }/>
                </div>
                <div className={"images_container " + paywallState.state}>
                    <img src={lochness_back} className="paywall_image" id="lochness_b"/>
                    <img src={lochness_front} className="paywall_image" id="lochness_f"/>
                </div>
                <div className="paywall_inner">
                    <div className="besked">
                        {paywallState.data ? (
                                <>
                                    <img className="storyPhoto" src={paywallState.data.imageURL}/>
                                    <h1><i>{paywallState.data.name}</i> kræver abonnement</h1>
                                    <h2>For 49 kroner om måneden kan du lytte til alle historierne, ubegrænset.</h2>
                                    <div className="tilmeld">
                                        <DefaultKnap tekst="Opret abonnement" overload="gul"
                                                     closure={() => window.location.href = "https://tilmeld.droemmehavet.dk"}/>
                                    </div>
                                    <LogindKnap/>
                                </>
                            ) :
                            <>
                                <h1>Få adgang til hele Drømmehavet</h1>
                                <div className="tilmeld">
                                    <DefaultKnap tekst="Opret abonnement" overload="gul"
                                                 closure={() => window.location.href = "https://tilmeld.droemmehavet.dk"}/>
                                    <LogindKnap/>
                                </div>
                            </>
                        }
                    </div>
                    <div id="blurry-bg"></div>
                </div>
            </div>

        </>)

}

function LogindKnap(props) {
    return (
        <div className="logind-container">
            <span>Har du allerede en konto?</span>
            <span>
                <input className="logind"
                       onClick={() => document.dispatchEvent(new CustomEvent('logind', {detail: true}))}
                       value=" Log ind"/>
            </span>
        </div>
    )
}

export default PayWall;