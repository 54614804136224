import React, {useRef, useContext, useState} from "react";
import {FirebaseContext} from "./Firebase";
import GoogleLogo from "../images/Google_G_Logo.svg";
import LoadingIkon from '../images/icons/loading.svg'
import {DefaultKnap, TilbageKnap} from "./Utility/Knapper";
import blaa from "../images/havet_assets/blaa_BG.png";

const Baggrund = () => {
    return (<img className="hav" src={blaa} draggable="false"/>)
}

export default function Login(props) {
    const firebase = useContext(FirebaseContext);
    const [loading, setLoading] = useState(false);
    const email = useRef();
    const password = useRef();
    const [enableSubmit, setEnableSubmit] = useState(true);
    const [glemtKode, setGlemtKode] = useState(false);
    const [Fejlbesked, setFejlbesked] = useState(null);

    const onChange = (e) => {
        e.preventDefault();
        if (!email.current) return
        if (!password.current) return

        if (email.current.value != "" && password.current.value != "") {
            setEnableSubmit(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const logind = await firebase.doSignInWithEmailAndPassword(
            email.current.value,
            password.current.value
        );

        switch (logind) {
            case "succes":
                props.setLogin(false)
                setLoading(false)
                break;
            case "auth/wrong-password":
                setFejlbesked("Kodeord matcher ikke");
                setLoading(false)
                break;
            case "auth/invalid-email":
                setFejlbesked("Kunne ikke finde matchene Email");
                setLoading(false)
                break;
            case "auth/user-not-found":
                setFejlbesked("Kunne ikke finde en matchene bruger");
                setLoading(false)
                break;
            case "auth/unverified-email":
                setFejlbesked("Emailen er ikke blevet valificeret");
                setLoading(false)
                break;
            case "auth/user-cancelled":
                setFejlbesked("Brugeren er opsagt");
                setLoading(false)
                break;
            default:
                setFejlbesked("Der skete en fejl under log ind. Prøv igen");
        }
    };

    const handleGoogleSignIn = async (e) => {
        setLoading(true);
        const logind = await firebase.doSignInWithGoogle();
    };

    return (
        <>
            {glemtKode ? <GlemtKode setGlemtKode={setGlemtKode} setLogin={props.setLogin}/> :
                <div className="bruger-input-container">
                    <TilbageKnap closure={() => props.setLogin(false)}/>
                    <div className="bruger-input-inner">
                        <div className="bruger-input-inner-box">

                            {Fejlbesked
                                ? <h1 id="fejlbesked">{Fejlbesked}</h1>
                                : null}
                            {/*              <button id="googleSignIn" onClick={handleGoogleSignIn}>*/}
                            {/*  <img src={GoogleLogo} />*/}
                            {/*  <p>Log ind med Google</p>*/}
                            {/*</button>*/}

                            {/* <p id="eller">eller</p> */}
                            {loading ?
                                <>
                                    <p id="loggerDigInd">Logger dig ind</p>
                                    <div className={"validator_ikon loading"}>
                                        <img className={"loading"} src={LoadingIkon} alt="Loading"/>
                                    </div>
                                </> :

                                <form onSubmit={handleSubmit}>
                                    <label>Email</label>
                                    <input className="formInput" type="email" ref={email} onChange={onChange}/>
                                    <label>Kode</label>
                                    <input className="formInput" type="password" ref={password} onChange={onChange}/>
                                    {/*Dette er en speciel log-ind-knap, og er derfor anderledes fra 'Knapper*/}
                                    <input
                                        className="knap gul submit"
                                        type="submit"
                                        value="Log ind"
                                        disabled={enableSubmit}
                                        onClick={() => setFejlbesked(null)}
                                    />

                                    <a id='glemtkodeNav' href="#" onClick={() => setGlemtKode(true)}>Glemt kodeord?</a>
                                </form>}
                        </div>
                        <span className="tilmeld knap outline"> {" "} eller <a
                            href="https://tilmeld.droemmehavet.dk">tilmeld dig</a></span>
                    </div>
                    {/*<Baggrund/>*/}
                </div>
            }
        </>
    );
}

function GlemtKode(props) {
    const firebase = useContext(FirebaseContext)
    const email = useRef()
    const [statusBesked, setStatusbesked] = useState("")
    const [succes, setSucces] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault()
        const firebaseBesked = await firebase.doResetPassword(email.current.value)
        if (firebaseBesked === -1) {
            setStatusbesked('Noget gik galt. Er du sikker på, du har skrevet emailen korrekt?')
        } else {
            setSucces(true)
        }
    }

    return (
        <div className="bruger-input-container">
            <TilbageKnap closure={() => {
                props.setGlemtKode(false)
                setSucces(false)
                props.setLogin(false)
            }}/>
            <div className="bruger-input-inner">
                <div className="bruger-input-inner-box">
                    <form onSubmit={handleSubmit}>
                        {!succes ?
                            <>
                                <div id="fejlbesked">{statusBesked}</div>
                                <label className="required">Email</label>
                                <input class="formInput" name="email" type="email" ref={email}
                                       onInput={() => setStatusbesked("")}/>
                                <DefaultKnap name={"submit"} type={'submit'} tekst={"Send nulstilslink"}/>
                            </>
                            : <p>Succes! Vi har sendt dig en email med et nulstillelseslink</p>}
                        <a id='glemtkodeNav' href="#" onClick={() => {
                            setSucces(false)
                            props.setGlemtKode(false)
                        }}>Tilbage til login</a>
                    </form>
                </div>
            </div>
            <Baggrund/>
        </div>
    )
}

