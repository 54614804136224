import React, {useState, useEffect, useRef, useContext} from "react";

import {editorContext} from "../App";
import {FirebaseContext} from "./Firebase";
import Character from "./Character";
import {AuthContext} from "../App";
import {TilbageKnap} from "./Utility/Knapper";
import {ACTION} from "./Editor/reducer";
import blaa from "../images/havet_assets/blaa_BG.png";

const Baggrund = () => {
    return (<img className="hav" src={blaa} draggable="false"/>)
}

export default function Island(props) {
    const {editorState, editorDispatch} = useContext(editorContext);
    const currentUser = useContext(AuthContext);
    const [map, setMap] = useState({});
    const [elements, setElements] = useState([]);
    const firebase = useContext(FirebaseContext);
    let islandDBref = editorState.renderEditor ? "islands_staging/" : "islands/";
    // const frame = useRef();
    // props.setFrame(props.frame);

    useEffect(() => {
        const location = document.location.href.split("/");
        const locationDecoded = decodeURIComponent(location[location.length - 1]);
        if (locationDecoded != "login") {
            if (!editorState.renderEditor) {
                document.dispatchEvent(new CustomEvent("fetchingStart"));
            }
            // setMap(editorState.currentPage.mapImageURL);
            firebase.getDocument(locationDecoded, islandDBref, (data) => {
                console.log(data)
                setMap({imageURL: data.mapImageURL, locked: data.locked});
            }).then(() => document.dispatchEvent(new CustomEvent("fetchingEnd")))

            firebase.getCollection((islandDBref + locationDecoded + "/elements/"), (data) => {
                setElements(data);
            }).then(() => document.dispatchEvent(new CustomEvent("fetchingEnd")))
        }
    }, [editorState.renderEditor, firebase, islandDBref]);

    return (
        <>
            <div id="text">
               <TilbageKnap closure = {
                   () => {
                       if (editorState.renderEditor && !editorState.saveChangesBtn) {
                           var answer = window.confirm("Forlad uden at gemme kladde?");
                           if (answer) {
                               editorDispatch({type: ACTION.UPDATE_EMPTY_CHARACTER_LIST});
                               editorDispatch({type: ACTION.UPDATE_EMPTY_ISLAND_LIST});
                               editorDispatch({type: ACTION.TOGGLE_SAVE_BTN, payload: true});
                               editorDispatch({type: ACTION.CURRENT_PAGE, payload: "/"});
                           }
                       } else {
                           editorDispatch({type: ACTION.CURRENT_PAGE, payload: "/"});
                       }
                   }}/>
            </div>
            {/*<div className="frame" ref={frame}>*/}
                {/*<Baggrund />*/}
                <div className="island-map-inner">
                    <img
                        className={ map.locked == "on" && ! currentUser  ? "map locked" : "map"}
                        src={map.imageURL}
                        alt=""
                        style={{minWidth: `${window.innerWidth}px`}}
                        draggable={false}
                    />
                    <div draggable={false}>
                        {/* Characters  */}
                        {Object.values(elements).map((data) => (
                            <Character
                                key={data.id}
                                data={data}
                                dragBegin={props.dragBegin}
                                draggable={props.draggable}
                            />
                        ))}
                    </div>

                </div>
            {/*</div>*/}
        </>
    );
}

