import React, {useContext, useState, useRef} from "react";
import {AuthContext} from "../App"
import {FirebaseContext} from "./Firebase"
import {reepay} from './Reepay/reepay'
import ProfilBillede from './ProfilBillede'
import {Lock} from "@material-ui/icons";
import {CloseKnap} from './Utility/Knapper'

export default function DashboardModal(props) {
    const firebase = useContext(FirebaseContext)
    const currentUser = useContext(AuthContext)
    const [newUserDetails, setNewUserDetails] = useState({})
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [statusBesked, setStatusbesked] = useState("")
    const [erLaast, setErLaast] = useState(true)
    const [kodeordReset, setKodeordReset] = useState(true)

    const fornavn = useRef()
    const efternavn = useRef()
    const email = useRef()

    const handleUserUpdateSubmit = async e => {
        e.preventDefault();
        // update user
        if (notEmptyValidate) {
            const customerData = await reepay.getCustomer(currentUser['reepay-customer-handle'])

            if (newUserDetails.fornavn != undefined) {
                customerData["first_name"] = newUserDetails.fornavn
            }
            if (newUserDetails.efternavn) {
                customerData["last_name"] = newUserDetails.efternavn
            }
            if (newUserDetails.email != undefined) {
                customerData["email"] = newUserDetails.email
            }

            const reepayUpdate = await reepay.updateCustomer(currentUser['reepay-customer-handle'], customerData);
            if (reepayUpdate == -1) {
                setStatusbesked('Hov, noget gik galt! Prøv igen senere!')
                return
            }

            const firebaseUpdate = await firebase.doUpdateFirebaseUser(currentUser.uid, newUserDetails);
            if (firebaseUpdate == -1) {
                setStatusbesked('Hov, noget gik galt! Prøv igen senere!')
                return
            }

            setStatusbesked('Profil opdateret!')


        }

    }
    const notEmptyValidate = () => {
        return fornavn.current.value == "" ||
            efternavn.current.value == "" ||
            email.current.value == ""
    }

    const handleChange = e => {
        console.log(e.currentTarget.value)
        setDisableSubmit(notEmptyValidate());
        setNewUserDetails({...newUserDetails, [e.currentTarget.name]: e.currentTarget.value})

    }

    return (
        <div className="dashboard">
            <CloseKnap navn={'profile'}/>

            <h1>Profilindstillinger</h1>
            {erLaast &&
            <div id="laas">
                <div id="blurry-bg"></div>
                <div id="laas-inner">

                    <Lock id="lock-icon"/> <h2 className="required">Hvad er den danske statsministers efternavn?</h2>
                    <form onSubmit={e => {
                        e.preventDefault()
                        if (e.target.mette.value.trim().toLowerCase() == 'frederiksen') {
                            setErLaast(false)
                        }
                    }
                    }>
                        <input name="mette" type="text"/>
                        <input name="submit" type="submit" value="Lås side op"/>
                    </form>
                </div>

            </div>
            }
            <div id="fejlbesked">{statusBesked}</div>
            <div className="bruger-input-box">
                <div className="bruger-input-box-inner">
                    <form onSubmit={handleUserUpdateSubmit}>
                        <input className="submit" type="submit" value="Gem ændringer" disabled={disableSubmit}/>

                        <label className="required">For -og mellemnavn(e)</label>
                        <input name="fornavn" type="text" ref={fornavn} onChange={handleChange}
                               defaultValue={currentUser.fornavn}/>

                        <label>Efternavn(e)</label>
                        <input name="efternavn" type="text" ref={efternavn} onChange={handleChange}
                               defaultValue={currentUser.efternavn}/>

                        <label className="required">Email</label>
                        <input name="email" type="email" ref={email} onChange={handleChange}
                               defaultValue={currentUser.email}/>

                        <div id="divider">
                            {kodeordReset ?
                                <div className={"nulstil"}>
                                    <input type="button" className="submit" onClick={() => {
                                        firebase.doResetPassword(currentUser.email)
                                        setKodeordReset(false)
                                    }
                                    } value="Nulstil kodeord"/>

                                    <p>Dette vil sende en email til <b>{currentUser.email}</b></p>
                                </div>
                                :
                                <div className={"nulstil"}>
                                    <p>Der er sendt en email til <b>{currentUser.email}</b></p>
                                </div>}
                        </div>
                    </form>
                   <ProfilBillede index={currentUser.photoID}/>

                </div>
                <br/>
                <p>Opsig abonnement? Send en mail til <a href="mailto:hejsa@droemmehavet.dk">hejsa@droemmehavet.dk</a>
                </p>

            </div>
        </div>
    );
}
