import {ExpandMore, ExpandLess, Cancel} from "@material-ui/icons";
import React, {useContext} from "react";
import {ACTION} from "../Editor/reducer";
import {Link} from "react-router-dom";
import {editorContext} from "../../App";

export function CloseKnap({navn, closure}) {
    if (closure) {
        return (<div className={"knap"} id="close" onClick={closure}>
            <Cancel className="Icon"/></div>)
    }
    if (navn) {
        return (<div id="close" onClick={() => document.dispatchEvent(
                new CustomEvent('modal', {
                    detail: null
                }))}>
                <Cancel className="Icon"/></div>
        )


    } else return null
}

export function SkjulKnap({state, closure}) {
    if (closure) {
        return (<div id="skjul" onClick={closure}>
            {state === 'aaben' ?
                <ExpandMore className="Icon"/>
                : null
                // <ExpandLess className="Icon"/>
            }

        </div>)
    } else return null
}

export function LoginKnap() {
    return (
        <input
            type="button"
            className="knap gul"
            id="logind"
            name="log ind"
            value="Log ind"
            onClick={
                () => document.dispatchEvent(
                    new CustomEvent('logind', {detail: true})
                )}/>

    )
}

export function TilbageKnap({closure}){

    return (
        <Link
            id="tilbage"
            to="/"
            onClick = {closure}>
            {<span id="tilbagepil">{"<"}</span>}
            Tilbage til kortet
        </Link>
    )
}

export function DefaultKnap({tekst, name, type, overload, closure}) {
    return (
        <input
            type={type ? type : "button"}
            className={'knap ' + overload}
            id="knap"
            name={name ? name : "defaultKnap"}
            value={tekst}
            onClick={closure ? closure : null}/>

    )
}

export function SideMenuKnap({tekst, id, closure, imgsrc }) {
    return (
        <div id = {id}  className="side-menu-knap"  onClick={ closure }>
            {imgsrc &&
                <img className="side-menu-knap-ikon" />
            }
            <input type="button"  value = { tekst }/>
        </div>)
}

export default null