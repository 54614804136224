import React, {useContext} from "react";
import {FirebaseContext} from "./Firebase";
import {AuthContext} from "../App";

export default function Character({data, dragBegin, draggable}) {
    // alle elementer har ikke data.visible endnu, så de fleste er null. Derfor skal dette tjek spørge efter ==false midlertidigt.
    if (data.visible == false) {
        return null
    }
    if (data.published === "on") {
        return (<EnabledCharacter data={data} draggable={draggable} dragBegin={dragBegin}/>)
    } else {
        return (<DisabledCharacter data={data} draggable={draggable} dragBegin={dragBegin}/>)
    }
}

function EnabledCharacter({data, draggable, dragBegin}) {
    const firebase = useContext(FirebaseContext);
    const currentUser = useContext(AuthContext)
    const pos = `translate(${data.x}px, ${data.y}px)`;

    const handleOnClick = (event) => {
        if (draggable.hasStarted) {
            event.preventDefault();
            return
        }

        if (currentUser) {
            document.dispatchEvent(
                new CustomEvent("modal", {detail: {player: data}})
            );

            firebase.analytics.logEvent('character', {
                name: data.name
            });
        } else if(data.locked !== 'on'){
            document.dispatchEvent(
                new CustomEvent("modal", {detail: {player: data}})
            );

            firebase.analytics.logEvent('character-ulaast', {
                name: data.name
            });
        } else {
            //duplicate code, jeg ved det
            document.dispatchEvent(
                new CustomEvent("laastStory", {detail:data})
            );
        }
    }

    return (
        <div
            className={data.locked === 'on' && !currentUser ? "character locked" : "character"}
            id={data.id}
            style={{transform: pos}}
            draggable="false"
            onMouseDown={dragBegin}
            onTouchStart={dragBegin}
            onClick={handleOnClick}
        >
            <div className="glow">  </div>
            <img
                id={`character_${data.name}`}
                alt={data.name}
                src={data.imageURL}
                name={data.name}>
            </img>
        </div>
    );
}

function DisabledCharacter({data, draggable, dragBegin}) {
    const pos = `translate(${data.x}px, ${data.y}px)`;

    const handleOnClick = (event) => {
        if (draggable.hasStarted) {
            event.preventDefault();
        }
    }
        return (
        <div
            className="character disabled"
            id={data.id}
            style={{transform: pos}}
            draggable="false"
            onMouseDown={dragBegin}
            onTouchStart={dragBegin}
            onClick={handleOnClick}
        >
            <img
                id={`character_${data.name}`}
                alt={data.name}
                src={data.imageURL}
                name={data.name}>
            </img>
        </div>
    );
}
