import React, {useState, useContext} from 'react'
import ProfilBillede from './ProfilBillede'
import {FirebaseContext} from "./Firebase"
import {AuthContext} from "../App"
import {editorContext} from '../App'
import {ACTION} from './Editor/reducer'
import {LoginKnap, SideMenuKnap} from "./Utility/Knapper";
import {Divider} from "./Utility/Divider";

const SideMenu = () => {
    const {editorState, editorDispatch} = useContext(editorContext)
    const [dropdown, setDropdown] = useState(false)
    const firebase = useContext(FirebaseContext)
    const currentUser = useContext(AuthContext)

    function EditorButton() {
        if (currentUser.admin) {
            return <input type="checkbox" defaultChecked={editorState.renderEditor} onChange={() => {
                editorDispatch({type: ACTION.TOGGLE_EDITOR})
            }}/>
        } else return null
    }

    if (currentUser != null) {
        return (
            <div className={dropdown ? 'menu-container' : 'menu-container closed'}>
                <div className='menu-container-inner' draggable="false">
                    <div>
                        <ProfilBillede index={currentUser.photoID} closure={() => setDropdown(!dropdown)}/>
                    </div>
                    <div className="menu-content">
                        <div className="menu-content-inner">

                            <SideMenuKnap tekst="Profilindstillinger"
                                          id="profilindstillinger"
                                          closure={
                                              () => {
                                                  // luk dropdown
                                                  setDropdown(false)
                                                  //åbn modal
                                                  document.dispatchEvent(
                                                      new CustomEvent('modal', {detail: {profil: true}}))
                                              }}/>

                            <Divider />
                            {/*<SideMenuKnap tekst="Webshop"*/}
                            {/*              id="webshop"*/}
                            {/*              closure={*/}
                            {/*                  () => {*/}
                            {/*                      // luk dropdown*/}
                            {/*                      setDropdown(false)*/}
                            {/*                      //send til webshop*/}
                            {/*                      window.open('https://droemmehavet.dk', '_blank')*/}
                            {/*                  }*/}
                            {/*              }*/}
                            {/*/>*/}

                            <SideMenuKnap tekst="Om Drømmehavet"
                                          id="hjemmeside"
                                          closure={
                                              () => {
                                                  // luk dropdown
                                                  setDropdown(false)
                                                  //send til webshop
                                                  window.open('https://droemmehavet.dk', '_blank')
                                              }
                                          }
                            />
                        </div>
                        <EditorButton/>
                        {/* log ud er stylet til at være i bunden altid */}
                        <SideMenuKnap tekst="Log ud"
                                      id="log-ud"
                                      closure={() => {
                                          firebase.doSignOut()
                                      }}
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='loginknap-container' draggable="false">
                <LoginKnap/>
            </div>

        )
    }
}
export default SideMenu;