import React from "react";
import baad from "../../images/LoaadingScreenImg/baad.png";
import sol from "../../images/LoaadingScreenImg/sol.png";
import waves from "../../images/LoaadingScreenImg/waves.png";

export default function LoadingScreen(props) {
  return (
    <div className="loading_screen">
        <div className="loading_screen_inner">
            {props.message ? <h1>{props.message}</h1> : 
            <h1>Sejler mod Ø</h1>
            }
            <h2 className="dot">.</h2>
            <img className="baad" src={baad}></img>
            <img className="sol" src={sol}></img>
            <img className="wave1" src={waves}></img>
            <img className="wave2" src={waves}></img>
            <img className="wave3" src={waves}></img>
      </div>
    </div>
  );
}
