import app from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/analytics";
import "@firebase/functions";
// import "regenerator-runtime/runtime";
// import {islands} from './developmentDB/data.js'
// tutorial: https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial

const devConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

export class Firebase {
    constructor() {
        app.initializeApp(devConfig);
        this.auth = app.auth();
        // this.auth.languageCode = 'dk'
        this.db = app.firestore();
        this.storage = app.storage();
        this.analytics = app.analytics();
        this.functions = app.functions();
    }

    // auth API for password and email
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = async (email, password) => {
        return this.auth
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                return "succes";
                // return {state: userCredential.user, msg: 'success'}
            })
            .catch((error) => {
                return error.code;
            });
    };
    doSignInWithGoogle = async () => {
        return this.auth
            .signInWithRedirect(new app.auth.GoogleAuthProvider())
            .then((userCredential) => {
                return userCredential;
            })
            .catch((error) => {
                console.error(error.code, error.message);
                return null;
            });
    };

    doSignOut = () => this.auth.signOut();
    doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
    doPasswordUpdate = (password) =>
        this.auth.currentUser.updatePassword(password);
    getCurrentUser = () => this.auth.currentUser;
    setUser = (user) => (this.user = user);
    doCreateFirestoreUser = async (id, user) => {
        await this.db
            .collection("users/")
            .doc(id)
            .set(user, {merge: true})
            .catch((error) => {
                console.error("Error adding document: ", error);
                return -1;
            });
    };
    doUpdateAuthUser = (user) =>
        this.auth.currentUser.updateProfile(user).catch((error) => {
            console.error(error);
            return error;
        });

    doUpdateFirebaseUser = async (uid, user) => {
        await this.db
            .collection("users/")
            .doc(uid)
            .update(user)
            .then((succes) => {
                return 1;
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                return -1;
            });
    };

    doResetPassword = async (email) => {
        this.auth.languageCode = "dk";
        return await this.auth
            .sendPasswordResetEmail(email)
            .then((succes) => {
                return 1;
            })
            .catch((error) => {
                console.error(error);
                return -1;
            });
    };

    //Storage API

    islandRef = (file, islandID) => {
        const islandIDWithUnderscore = islandID.toLowerCase().split(" ").join("_");
        console.log("islands/" + islandIDWithUnderscore + "/" + file.name);

        return this.storage
            .ref()
            .child("islands/" + islandIDWithUnderscore + "/" + file.name);
    };

    elementRef = (file, islandName) => {
        const islandNameWithUnderscore = islandName
            .toLowerCase()
            .split(" ")
            .join("_");
        return this.storage
            .ref()
            .child("islands/" + islandNameWithUnderscore + "/" + file.name);
    };

    uploadIsland = async (file, islandID) => {
        const islandIDWithUnderscore = islandID.toLowerCase().split(" ").join("_");
        // console.log(islandIDWithUnderscore)
        const ref = this.storage
            .ref()
            .child("islands/" + islandIDWithUnderscore + "/" + file.name);
        await ref.put(file);
    };

    uploadElement = async (file, islandName) => {
        const islandNameWithUnderscore = islandName
            .toLowerCase()
            .split(" ")
            .join("_");
        await this.storage
            .ref()
            .child("islands/" + islandNameWithUnderscore + "/" + file.name)
            .put(file);
    };

    // Database API
    async getUserFromDB(uid) {
        const userRef = this.db.collection("users").doc(uid);
        const doc = await userRef.get();
        if (!doc.exists) {
            console.error("No such user!");
        } else {
            return doc.data();
        }
        return doc;
    }

    async putIsland(DB, mapImageURL, name, locked, published, x, y, imageURL) {
        let IDWithUnderscore = name.toLowerCase().split(" ").join("_");
        let island = {
            id: IDWithUnderscore,
            imageURL: imageURL,
            mapImageURL: mapImageURL,
            name: name,
            x: x,
            y: y,
            locked: locked,
            published: published,
        };

        await this.db
            .collection(DB)
            .doc(IDWithUnderscore)
            .set(island, {merge: true})
            .catch((error) => {
                console.error("Error adding document: ", error);
                return -1;
            });
    }

    async updateIslandDetails(
        imageURL,
        mapImageURL,
        name,
        id,
        locked,
        published
    ) {
        console.log("id:");
        console.log(id);
        let IDWithUnderscore = id.toLowerCase().split(" ").join("_");
        let island = {
            imageURL: imageURL,
            mapImageURL: mapImageURL,
            name: name,
            locked: locked,
            published: published,
        };

        await this.db
            .collection("islands/")
            .doc(IDWithUnderscore)
            .update(island)
            .catch((error) => {
                console.error("Error adding document: ", error);
                return -1;
            });
    }

    async updateIsland(islandID, collection, island) {
        let islandIDWithUnderscore = islandID.toLowerCase().split(" ").join("_");
    // note to self: islandID (island.id) er et Objekt lige nu, ikke en String
        await this.db
            .collection(collection)
            .doc(islandIDWithUnderscore)
            .update(island)
            .catch((error) => {
                console.error("Error adding document: ", error);
                return -1;
            });
    }


    async putElements(
        islandID,
        collection,
        imageURL,
        audioURL,
        name,
        audioFileName,
        imageFileName,
        published,
        locked,
        x,
        y
    ) {
        const elementIDWithUnderscore = name.toLowerCase().split(" ").join("_");
        const islandNameWithUnderscore = islandID
            .toLowerCase()
            .split(" ")
            .join("_");
        await this.db
            .collection(collection)
            .doc(islandNameWithUnderscore)
            .collection("elements")
            .doc(elementIDWithUnderscore)
            .set({
                id: elementIDWithUnderscore,
                imageURL: imageURL,
                audioURL: audioURL,
                audioFileName: audioFileName,
                imageFileName: imageFileName,
                name: name,
                x: x,
                y: y,
                locked: locked,
                published: published,
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                return -1;
            });
    }

    async updateElementDetails(
        islandID,
        imageURL,
        audioURL,
        name,
        id,
        audioFileName,
        imageFileName,
        locked,
        published,
        x,
        y
    ) {
        let element = {
            name: name,
            imageURL: imageURL,
            audioURL: audioURL,
            x: x,
            y: y,
            locked: locked,
            published: published,
            audioFileName: audioFileName,
            imageFileName: imageFileName,
        };

        await this.db
            .collection("islands/" + islandID + "/elements")
            .doc(id)
            .update(element)
            .catch((error) => {
                console.error("Error adding document: ", error);
                return -1;
            });
    }

    async updateElement(id, collection, islandID, element) {
        console.log(id, islandID, element);
        await this.db
            .collection(collection + "/" + islandID + "/elements")
            .doc(id)
            .update(element)
            .catch((error) => {
                console.error("Error adding document: ", error);
                return -1;
            });
    }


    async getCollection(query, closure) {
        console.log(query)

        return this.db
            .collection(query)
            .onSnapshot(doc => {
                let elements = [];
                doc.forEach((doc) => {
                    elements = [...elements, doc.data()];
                });
                // elements.map(el => console.log(el.name))
                return closure.call(this, elements)
            })
    }

    async getCollection1(query, closure) {
        const queryWithUnderscore = query.toLowerCase().split(" ").join("_");
        console.log('getFromIslandDB!',queryWithUnderscore)
        return this.db
            .collection(queryWithUnderscore)
            .onSnapshot((querySnapshot) => {
                let a = {}
                // console.log(querySnapshot)
                querySnapshot.forEach((doc) => {
                    a[doc.data().name] = doc.data();
                });
                return closure.call(this, a)
            })
    }

    async getDocument(island, collection, closure) {
        const islandWithUnderscore = island.toLowerCase().split(" ").join("_");
        console.log(collection, islandWithUnderscore)
        const islandRef = this.db.collection(collection).doc(islandWithUnderscore);
        return islandRef.onSnapshot(doc => {
            if (!doc.exists) {
                console.log("No such document!");
            } else {
                console.log(doc.data())
                return closure.call(this, doc.data())
            }
        });
    }

}

export default Firebase;
