import React, { useState, useEffect, useRef } from "react";
import {
  PlayArrow,
  Pause,
  VolumeUp,
  Forward30,
  Replay30,
  VolumeMute,
  VolumeOff,
  VolumeDown,
} from "@material-ui/icons";

export default function AudioControls(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(JSON.parse(localStorage.getItem(props.audio)) || 0)
  const [volumeIcon, setVolumeIcon] = useState(<VolumeUp />);
  const [volume, setVolume] = useState(80);
  const [finshedLisning, setFinshedLisning] = useState(false);

  const audio = useRef();

  useEffect(() => {
    audio.current.currentTime = currentDuration;

    document.addEventListener('closeStoryWindow', () => {
      if(audio.current != null){
        close();
      }
    }, false)
  }, [])


  useEffect(() => {
    if (volume * 100 > 40) {
      setVolumeIcon(<VolumeUp />);
    }
    else if (volume * 100 <= 40 && volume * 100 > 10) {
      setVolumeIcon(<VolumeDown />);
    }
    else if (volume * 100 <= 10 && volume * 100 > 5) {
      setVolumeIcon(<VolumeMute />);
    }
    else if (volume * 100 <= 5) {
      setVolumeIcon(<VolumeOff />);
    }
  }, [volume]);

  const styleProgressBar = {
    background:
      `linear-gradient(to right,
      #fff 0%, 
      #fff ${currentDuration * 100 / duration}%,
      #36435B ${currentDuration * 100 / duration}%, #36435B  100%)`
  }

  const styleVolumebar = {
    background:
      `linear-gradient(to right,
      #fff 0%, 
      #fff ${volume * 100}%,
      #36435B ${volume * 100}%, #36435B  100%)`
  }

  const setLocalStorage = () => {
    localStorage.setItem(props.audio, JSON.stringify(audio.current.currentTime))
  }
  const formatMinSec = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + ~~s;
  }

  const setFinish = () => {
    setFinshedLisning(true);
    console.log("audioEnded");
    console.log(finshedLisning);
  }


  const updateVolume = (q) => {
    setVolume(q);
    audio.current.volume = q;
    if (volume < 0.05) {
      audio.current.volume = 0.0;
    }
  }
  const updateCurrentTime = (e) => {
    const time = (e.target.value * duration) / 100;
    setCurrentDuration(time)
    audio.current.currentTime = time;

  }

  const stepBack = () => {
    const time = audio.current.currentTime - 30
    console.log(time)
    setCurrentDuration(time);
    audio.current.currentTime = time;
    if (time < 0.00) {
      setCurrentDuration(0);
      audio.current.currentTime = 0;
    }
  }

  const stepForward = () => {
    const time = audio.current.currentTime + 30
    setCurrentDuration(time);
    audio.current.currentTime = time;
    if (time < 0.00) {
      setCurrentDuration(duration);
      audio.current.currentTime = duration;
    }
  }

  const playPauseAudio = () => {
    setIsPlaying(!isPlaying);
    isPlaying ? audio.current.pause() : audio.current.play();
  }

  const close = () => {
    setIsPlaying(true);
      setLocalStorage(props.audio, audio.current.currentTime);
      if (isPlaying) {
        playPauseAudio()
      }
    
      props.setOpen(false);
    }
  

    return (
      <div className="audioPlayer">

        <audio
          ref={audio}
          src={props.audio}
          preload="auto"
          onEnded={() => setFinish()}
          onCanPlay={(e) => {
            setDuration(e.target.duration);
          }
          }

          onTimeUpdate={e => setCurrentDuration(e.target.currentTime)} // isTimeInPrograss ? console.log("Error") : setCurrentDuration(e.target.currentTime)}
        />

        <div className="audioLogic">
          <div className="audioButtons">
            <button className="setBack30" onClick={() => stepBack()}>
              <Replay30 className="Icon" />
            </button>

            <button
              id="playPauseAudio"
              onClick={() => { playPauseAudio(); }}
            >
              {isPlaying ? (
                <Pause className="Icon" />
              ) : (
                  <PlayArrow className="Icon" />
                )}
            </button>
            <button className="setForward30" onClick={() => stepForward()}>
              <Forward30 className="Icon" />
            </button>

            <div className="volume-hover-container">
              <button className="volume" >
                {volumeIcon}
              </button>

              <div className="volume-hover">
                <input
                  style={styleVolumebar}
                  type="range"
                  name="volume"
                  className="volumeRange"
                  min="0"
                  max="100"
                  value={Math.round(volume * 100)}
                  onChange={(e) => {
                    updateVolume(e.target.value / 100);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="timeBar">
            <span className="currentTime">
              {formatMinSec(currentDuration)}
            </span>
            <input
              type="range"
              name="time"
              style={styleProgressBar}
              value={duration ? (currentDuration * 100) / duration : 0}
              id="timeRange"

              min="0"
              onInput={updateCurrentTime}
            ></input>
            <span className="TotalTime">{formatMinSec(duration)}</span>
          </div>
        </div>
      </div>
    );
  }
