import React, {useRef, useEffect, useState, useContext} from "react";
import {
    Switch,
    Link,
    Route,
} from "react-router-dom";

//components
import Island from "./Island";
// import blaa from "../images/havet_assets/Kortet_BG.png";
import blaa from "../images/havet_assets/Kortet_BG_blurry.png";
import blaa_webp from "../images/havet_assets/Kortet_BG_blurry.webp";
import skyBtmRight from "../images/havet_assets/bottom-right.png";
import skyBtmLeft from "../images/havet_assets/bottom-left.png";
import skyTopLeft from "../images/havet_assets/top-left.png";
import skyTopRight from "../images/havet_assets/top-right.png";

import wave1 from "../images/havet_assets/waves/wave1.png";
import wave2 from "../images/havet_assets/waves/wave2.png";
import wave3 from "../images/havet_assets/waves/wave3.png";
import wave4 from "../images/havet_assets/waves/wave4.png";
import wave5 from "../images/havet_assets/waves/wave5.png";
import wave6 from "../images/havet_assets/waves/wave6.png";
import wave7 from "../images/havet_assets/waves/wave7.png";

import fisk1 from "../images/havet_assets/fisk1.png";
import fisk2 from "../images/havet_assets/stime.png";

import {FirebaseContext} from "./Firebase";
import {AuthContext, editorContext} from "../App";
import {ACTION} from "./Editor/reducer";

const Baggrund = () => {
    return (
        <picture>
            <source className="hav"  srcSet={blaa_webp} type="image/webp"/>
            <source className="hav"  srcSet={blaa} type="image/png"/>
            <img className="hav" src={blaa} draggable="false"/>
        </picture>)
}

export default function Havet(props) {
    const [islands, setIslands] = useState([]);
    const firebase = useContext(FirebaseContext);
    const currentUser = useContext(AuthContext);
    const {editorState, editorDispatch} = useContext(editorContext);
    const frame = useRef();
    let islandDBref = editorState.renderEditor ? "islands_staging/" : "islands/";
    props.setFrame(frame);

    const [draggable, setDraggable] = useState({
        element: undefined,
        isDragging: false,
        hasStarted: false,
        startPosition: {x: 0, y: 0},
        objectPosition: {x: 0, y: 0},
        timer: undefined,
        zIndex: "",
        filter: "",
        transition: "",
    });

    // time before a drag begins (ms)
    const dragHoldTime = 500;

    /**
     * Begin dragging an element
     * @event evt
     * @param evt
     */
    function dragBegin(evt) {
        evt = evt || window.event;

        // listen for pointer move events on window to catch all movements, and not only those on the element itself

        if (!editorState.renderEditor) {
            return null;
        }

        frame.current.addEventListener("mouseup", dragEnd);
        frame.current.addEventListener("touchend", dragEnd);
        frame.current.addEventListener("mousemove", dragMove);
        frame.current.addEventListener("touchmove", dragMove);

        // handle mouse/touch differently
        if (evt.type === "touchstart") {
            evt.preventDefault();
            draggable.startPosition = getPointerPosition(evt, true);
        } else {
            draggable.startPosition = getPointerPosition(evt, false);
        }

        draggable.element = evt.currentTarget;
        draggable.hasStarted = false;
        setDraggable(draggable);

        // wait for drag to begin...
        draggable.timer = setTimeout(() => {
            editorDispatch({type: ACTION.TOGGLE_SAVE_BTN, payload: false});
            editorDispatch({type: ACTION.TOGGLE_PUBLISH_BTN, payload: true})
            // get elements current translate value from its transform matrix
            const style = window.getComputedStyle(draggable.element);
            const matrix = new DOMMatrix(style.transform);
            draggable.objectPosition = {x: matrix.m41, y: matrix.m42}; // translate x, y

            // apply filter transition!
            draggable.transition = draggable.element.style.transition;
            draggable.element.style.transition = "filter 200ms ease-out";

            // put the element on top!
            draggable.zIndex = draggable.element.style.zIndex;
            draggable.element.style.zIndex = "10000";

            // style the element!
            draggable.filter = draggable.element.style.filter;
            draggable.element.style.filter =
                "brightness(120%) drop-shadow(0 0 20px rgba(0, 0, 0, 0.5))";

            // update react state
            draggable.isDragging = true;
            draggable.hasStarted = true;
            setDraggable(draggable);

            props.setCanDrag(false);
            document.body.classList.add("noselect");
        }, dragHoldTime);
    }

    /**
     * Drag an element on move
     * @event evt
     * @param evt
     */
    function dragMove(evt) {
        evt = evt || window.event;

        // handle mouse/touch differently
        let pos;
        if (evt.type === "touchmove") {
            evt.preventDefault();
            pos = getPointerPosition(evt, true);
        } else {
            pos = getPointerPosition(evt, false);
        }

        // calculate offsets
        let ox = pos.x - draggable.startPosition.x;
        let oy = pos.y - draggable.startPosition.y;

        // cancel the drag, if frame is being dragged, otherwise move the element
        if (!draggable.hasStarted) {
            const distance = Math.sqrt(ox * ox + oy * oy);
            if (distance > props.dragTolerance) {
                clearTimeout(draggable.timer);
                window.removeEventListener("mousemove", dragMove);
                window.removeEventListener("touchmove", dragMove);
            }
        } else {
            // reposition element
            pos.x = draggable.objectPosition.x + ox * 2;
            pos.y = draggable.objectPosition.y + oy * 2;
            draggable.element.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
        }

        setDraggable(draggable);
    }

    /**
     * Stop dragging an element
     * @event evt
     * @param evt
     */
    function dragEnd(evt) {
        evt = evt || window.event || frame.current.event;
        // prevent mouse events when touch
        if (evt.type === "touchend") {
            evt.preventDefault();
        }

        // reset, if drag happened
        if (draggable.hasStarted) {
            // stop responding to pointer move events
            frame.current.removeEventListener("mousemove", dragMove);
            frame.current.removeEventListener("touchmove", dragMove);

            // reset z-index and filter
            draggable.element.style.zIndex = draggable.zIndex;
            draggable.element.style.filter = draggable.filter;
            draggable.element.style.transition = draggable.transition
        }
        // save new island position
        saveIslandPosition(draggable.element);
        // cancel timer
        clearTimeout(draggable.timer);

        // update state
        draggable.isDragging = false;
        setDraggable(draggable);

        props.setCanDrag(true);
        document.body.classList.remove("noselect");
    }

    /**
     * Get mouse or touch position from event
     * @event evt
     * @param evt
     * @param {boolean} fromTouch
     */
    function getPointerPosition(evt, fromTouch = false) {
        return fromTouch
            ? {x: evt.targetTouches[0].pageX, y: evt.targetTouches[0].pageY}
            : {x: evt.pageX, y: evt.pageY};
    }

    /**
     * Save the position of the island to the database
     * @param {Element} elem
     */
    function saveIslandPosition(elem) {
        let style = window.getComputedStyle(elem);
        let matrix = new DOMMatrix(style.transform);
        const x = matrix.m41;
        const y = matrix.m42;
        //* save to global editor-state object

        if (elem.classList.contains("island")) {
            editorDispatch({
                type: ACTION.UPDATE_ADD_ISLAND,
                payload: {island: {id: elem.id, x: x, y: y}},
            });
            // console.log(editorState.islandsToUpdate)
        } else if (elem.classList.value === "character") {
            editorDispatch({
                type: ACTION.UPDATE_ADD_CHARACTER,
                payload: {character: {id: elem.id, x: x, y: y}},
            });
            console.log(editorState.charactersToUpdate)
        }
    }

    const setStartPosition = (x,y) => {
        if((x && y) && frame.current) {
            frame.current.scrollTo(x,y)
        }
    }

    useEffect(() => {

        firebase.getCollection(islandDBref, (data) => {
            setIslands(data)
        });

        if(!currentUser){
            setStartPosition(752, -1012)
        }

    }, [editorState.renderEditor, firebase,]);

    return (
        <div id="map-container">
            <div className="frame" ref={frame}>

                <Switch>
                    <Route exact path="/">
                        <div>
                            <div className="skyer safari_transform">
                                <img className="sky" id="btm-right" src={skyBtmRight} alt=""/>
                                <img className="sky" id="btm-left" src={skyBtmLeft} alt=""/>
                                <img className="sky" id="top-left" src={skyTopLeft} alt=""/>
                                <img className="sky" id="top-right" src={skyTopRight} alt=""/>
                            </div>
                            {Object.values(islands).map((data) => (
                                <IslandButton
                                    data={data}
                                    dragBegin={dragBegin}
                                    draggable={draggable}
                                    key={data.id}
                                />
                            ))}
                            <div className="waves-and-fish safari_transform">
                                <img className="fisk" id="f1" src={fisk1} alt=""/>
                                <img className="fisk" id="f2" src={fisk2} alt=""/>
                                <img className="wave" id="w1" src={wave1} alt=""/>
                                <img className="wave" id="w2" src={wave2} alt=""/>
                                <img className="wave" id="w3" src={wave3} alt=""/>
                                <img className="wave" id="w4" src={wave4} alt=""/>
                                <img className="wave" id="w5" src={wave5} alt=""/>
                                <img className="wave" id="w6" src={wave6} alt=""/>
                                <img className="wave" id="w7" src={wave7} alt=""/>
                            </div>
                            <Baggrund />
                        </div>
                    </Route>
                    <Route path="/:name">
                            <Island
                                moveFrameStartingPoint={props.moveFrameStartingPoint}
                                setFrame={props.setFrame}
                                frame={frame}
                                refresh={props.refresh}
                                location={props.location}
                                dragBegin={dragBegin}
                                draggable={draggable}
                            />
                        </Route>
                </Switch>

            </div>
        </div>
    );
}

function IslandButton(props) {
    if (props.data.published === "on") {
        return (
            <EnabledIsland
                data={props.data}
                dragBegin={props.dragBegin}
                draggable={props.draggable}
            />
        )

    }
    else{
        return (
            <DisabledIsland
                data={props.data}
                dragBegin={props.dragBegin}
                draggable={props.draggable}
            />
        )
    }

}

function EnabledIsland({data, dragBegin, draggable}){
    const {editorDispatch} = useContext(editorContext);
    const firebase = useContext(FirebaseContext);
    const currentUser = useContext(AuthContext)

    const handleOnClick = (event) => {
        if (draggable.hasStarted) {
            event.preventDefault();
            return
        }

        if (data.locked === "on" && !currentUser) {
            event.preventDefault();
            document.dispatchEvent(
                new CustomEvent("laastStory", {detail: data})
            );
        } else {
            editorDispatch({
                type: ACTION.CURRENT_PAGE,
                payload: data.id,
            });
            firebase.analytics.logEvent("island", {
                name: data.name,
            });
        }
    }
    return (
        <div
            className={data.locked === "on" && !currentUser ? "island safari_transform locked" : "island safari_transform"}
            id={data.id}
            style={{transform: `translate(${data.x}px, ${data.y}px)`}}
            draggable="false"
            onMouseDown={dragBegin}
            onTouchStart={dragBegin}
            key={data.id}
        >
            <Link
                to={`/${data.id}`}
                draggable="false"
                onClick={handleOnClick}
            >
                <div
                    className="island-inner"
                    style={{userSelect: "none", pointerEvents: "none"}}
                    draggable="false"
                >
                    <h1 className="islandTitle">{data.name}</h1>
                    <div className={data.cssGradient ? "islandImage-container cssgradient" : "islandImage-container"}>
                        <img
                            className={data.cssGradient ? "islandImage cssgradient" : "islandImage dropshadow"}
                            src={data.imageURL}
                            alt=""
                            width="auto"
                            height="auto"
                        />
                    </div>
                    { data.locked !== "on" && !currentUser ?
                            <div className="glow"></div>
                        : null
                    }
                </div>
            </Link>
        </div>
    );


}
function DisabledIsland({data, dragBegin}) {
    return (
        <div
            className="island safari_transform"
            id={data.id}
            style={{transform: `translate(${data.x}px, ${data.y}px)`}}
            draggable="false"
            onMouseDown={dragBegin}
            onTouchStart={dragBegin}
            key={data.id}
        >
                <div
                    className="island-inner"
                    style={{userSelect: "none", pointerEvents: "none"}}
                    draggable="false"
                >
                    <h1 className="islandTitle">{data.name}</h1>
                    <div className={data.cssGradient ? "islandImage-container cssgradient" : "islandImage-container"}>
                        <img
                            className="islandImage"
                            src={data.imageURL}
                            alt=""
                            width="auto"
                            height="auto"
                        />
                    </div>
                </div>

        </div>
    );

}