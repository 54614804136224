import React, {useState} from 'react'
import Havet from './Havet.js'

// Dette component kan håndtere delt funktionalitet for alle 'map'-komponenter (aka inheritence)

export default function Map(props) {
    const [mouseDown, setMouseDown] = useState(false);
    const [scroll, setScroll] = useState({
        'isScrolling': false,
        'isSetup': false,
        'offsetX': 0, 'offsetY': 0,
        'startX': 0, 'startY': 0,
        'frameX': 0, 'frameY': 0,
    });
    const [isDraggingFrame, setIsDraggingFrame] = useState(false);
    const [dragTolerance, setDragTolerance] = useState(7);
    const [frame, setFrame] = useState(null);
    const [canDrag, setCanDrag] = useState(true);

    function onMouse(event) {
        event = event || window.event;

        if (mouseDown && canDrag) {
            document.body.classList.add('noselect');

            const pointerX = event.clientX;
            const pointerY = event.clientY;

            // allow small wiggle room (dragTolerance) before dragging is considered started
            if (!isDraggingFrame) {
                const ox = pointerX - scroll.startX;
                const oy = pointerY - scroll.startY;
                const distance = Math.sqrt(ox * ox + oy * oy);
                setIsDraggingFrame(distance > dragTolerance);
            }

            scroll.isScrolling = true;
            scroll.offsetX = pointerX - scroll.startX;
            scroll.offsetY = pointerY - scroll.startY;
            setScroll(scroll);

            moveFrame();
        }
    }

    function onMouseDown(event) {
        event = event || window.event;

        setMouseDown(true);
        setIsDraggingFrame(false);

        scroll.isScrolling = false;
        scroll.startX = event.clientX;
        scroll.startY = event.clientY;
        setScroll(scroll);
    }

    function onMouseUp(event) {
        event = event || window.event;

        setMouseDown(false);
        document.body.classList.remove('noselect');

        scroll.isSetup = false;
        setScroll(scroll);

        if (frame != null) {
            frame.current.style.pointerEvents = 'unset';
            frame.current.style.touchAction = 'unset';
        }
    }

    function moveFrame() {
        if (frame == null || frame.current == null) {
            console.log("frame is null")
            return;
        }

        if (!scroll.isSetup) {
            scroll.isSetup = true;
            scroll.offsetX = 0;
            scroll.offsetY = 0;
            scroll.frameX = frame.current.scrollLeft;
            scroll.frameY = frame.current.scrollTop;
            setScroll(scroll);
        }

        if (scroll.isScrolling) {
            frame.current.scrollLeft = scroll.frameX - scroll.offsetX;
            frame.current.scrollTop = scroll.frameY - scroll.offsetY;
        }


        if (isDraggingFrame) {
            frame.current.style.pointerEvents = 'none';
            frame.current.style.touchAction = 'none';
        }
    }

    return (
        <div id='map-block'>
            <div onMouseMove={e => onMouse(e)} onMouseDown={e => onMouseDown(e)} onMouseUp={e => onMouseUp(e)}
                 onMouseLeave={e => onMouseUp(e)}>
                <Havet
                    onMouseUp={onMouseUp}
                    setFrame={setFrame}
                    setCanDrag={setCanDrag}
                    setIsDragging={setIsDraggingFrame}
                    isDraggingFrame={isDraggingFrame}
                    dragTolerance={dragTolerance}
                />

            </div>
        </div>)

}
