import React, { useEffect, useState } from "react";
import AudioControls from "./AudioControls";
import {
  Cancel
} from "@material-ui/icons";
import { CloseKnap } from './Utility/Knapper'

export default function PlayerModal({ story }) {
  console.log(story)
  if (story != undefined) {
    return (
      <>
          <div className={"playerContainer"}>
            <div className="player-inner">
            {/*<button id="close" onClick={() => document.dispatchEvent(*/}
            {/*                            new CustomEvent('closeStoryWindow'))}><Cancel className="Icon" /></button>*/}
              <CloseKnap navn="story"/>
              <img className="storyPhoto" src={story.imageURL} />
              <div className="title-controls-outer">
                <div className="title-controls-inner">
                  <h1>{story.name}</h1>
                  <AudioControls
                    audio={story.audioURL}
                  />
                </div>
              </div>
            </div>
          </div>
      </>
    );
  } else {
    return (
      <>
        <div className="player-outer-container">
          <div className={ "playerContainer"}>
            <div className="player-inner">
              <h1>Indlæser historie...</h1>
            </div>
          </div>
        </div>
      </>
    );
  }
}
