import React, {createContext, useContext, useEffect, useReducer, useState,} from "react";
import {FirebaseContext} from "./components/Firebase";
import {initialState, reducer} from "./components/Editor/reducer";
import EditorShelf from "./components/Editor/EditorShelf";
import Header from "./components/Header";
import Map from "./components/Map";
import Login from "./components/Login";
import LoadingScreen from "./components/Utility/LoadingScreen";
import SideMenu from "./components/SideMenu";
import Modal from "./components/Modal"
import "./App.scss";
import PayWall from "./components/PayWall";
import {LoginKnap} from "./components/Utility/Knapper";

export const editorContext = createContext();
export const AuthContext = createContext();

export default function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [editorState, editorDispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [login, setLogin] = useState(false);
  const [paywallState, setPaywallState] = useState({data: null, state: ""})

  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    return firebase.auth.onAuthStateChanged((user) => {
      if (!user) {
        setCurrentUser(null);
      }
      firebase
          .getUserFromDB(user.uid)
          .then((firestoreUser) => {
            firestoreUser.uid = user.uid;
            setCurrentUser(firestoreUser);
          });
    });
  }, [firebase]);

  useEffect(() => {
    document.addEventListener(
      "modal",
      function (e) {
        setModal(e.detail);
      },
      false
    );

    document.addEventListener(
      "logind",
      function (e) {
        setLogin(e.detail);
      },
      false
    );

    document.addEventListener(
      "fetchingStart",
      () => {
        setIsLoading(true);
        // console.debug("start");
      },
      false
    );

    document.addEventListener("fetchingEnd", () => {
      setTimeout(() => setIsLoading(false), 2000);
      // console.debug("done");
    });

  document.addEventListener(
      "laastStory",
      function (e) {
          setPaywallState({data: e.detail, state: "aaben"})
      },false
  );
  }, []);

  const editor = () => {
    if (editorState.renderEditor) {
      return (
        <span className="editor-app">
          <EditorShelf />
        </span>
      );
    } else {
      return null;
    }
  };

  const paywall = () =>{
      if(currentUser) {
         return (null)
      }
      else{
          return (
              <PayWall paywallState={paywallState} setPaywallState={setPaywallState} modal={modal}/>
          )
      }
  }

  if (login) {
    return (
      <AuthContext.Provider value={currentUser}>
          <editorContext.Provider value={{ editorState, editorDispatch }}>
              <Header />
              <Login setLogin={setLogin}/>
          </editorContext.Provider>
      </AuthContext.Provider>
    );
  }
else {
  return (
    <AuthContext.Provider value={currentUser}>
      <editorContext.Provider value={{ editorState, editorDispatch }}>
        <Header/>
        <SideMenu />
            <div className="body-inner">
            {isLoading ? <LoadingScreen /> : null}
            {editor()}
            {paywall()}
            <Modal modal={modal} />
            <span className={`map-app ${ editorState.renderEditor ? "editor-enabled" : "" }`} >
              <Map />
            </span>
            </div>

      </editorContext.Provider>
    </AuthContext.Provider>
  );
  }
}