import React from 'react';
import DashboardModal from "./DashboardModal";
import PlayerModal from "./PlayerModal";
import {CloseOutside} from "./Utility/CloseOutside";


const Modal = ({modal}) => {
    if ( modal ){
        return (
            <div className='modal'>
                {modal.profil && <DashboardModal/>}
                {modal.player && <PlayerModal story={modal.player}/>}

                <CloseOutside closure={() =>
                    document.dispatchEvent(
                        new CustomEvent('modal', {
                            detail: null
                        }))}/>
            </div>);
    }
    else return null
};

export default Modal;