import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {editorContext} from "../../App";
import {FirebaseContext} from "../Firebase";
import {ACTION} from "./reducer";
import Spinner from "./spinner.png";
import {UpdateWindow} from "./UpdateWindow";
import {ElementsModal, IslandModal} from "./UploadModals";

export default function EditorShelf(props) {
    const {editorState, editorDispatch} = useContext(editorContext);
    const [file, setFile] = useState({});
    const [elements, setElements] = useState([]);
    const [islands, setIslands] = useState([]);
    const [modal, setModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [elementInFocus, setElementInFocus] = useState({});
    const firebase = useContext(FirebaseContext);
    let islandDBref = editorState.renderEditor ? "islands_staging/" : "islands/";
    const [location, setLocation] = useState("/");
    const loc = useLocation();

    useEffect(() => {
        const full_address = document.location.href.split("/");
        const current_location = decodeURIComponent(full_address[full_address.length - 1]);
        if (current_location.length != "") {
            setLocation(current_location);
        }
        let query = matchExact(location, "/") ? islandDBref : islandDBref + location + "/elements/";

        firebase.getCollection(query, (data) => {
            if (matchExact(location, "/")) {
                setIslands(data);
            } else {
                setElements(data);
            }
        });

    }, [firebase, loc, location]);

    const onUploadIsland = async (event) => {
        event.preventDefault();
        setIsUploading(true);
        await firebase.uploadIsland(file.mainImage, event.target.name.value);
        await firebase.uploadIsland(file.secondaryImage, event.target.name.value);

        let mainImageUrl = await firebase
            .islandRef(file.mainImage, event.target.name.value)
            .getDownloadURL();
        let secondaryImageUrl = await firebase
            .islandRef(file.secondaryImage, event.target.name.value)
            .getDownloadURL();
        await firebase.putIsland(islandDBref, secondaryImageUrl, event.target.name.value, event.target.locked.value, event.target.published.value, event.target.x.value, event.target.y.value, mainImageUrl);
        setIsUploading(false);
        setModal(false);
    };

    const onUploadElement = async (event) => {
        event.preventDefault();
        setIsUploading(true);
        await firebase.uploadElement(file.mainImage, location);
        await firebase.uploadElement(file.mainAudio, location);
        let imageUrl = await firebase
            .elementRef(file.mainImage, location)
            .getDownloadURL();
        let audioUrl = await firebase
            .elementRef(file.mainAudio, location)
            .getDownloadURL();
        await firebase.putElements(
            location,
            "islands_staging",
            imageUrl,
            audioUrl,
            event.target.name.value,
            file.mainAudio.name,
            file.mainImage.name,
            event.target.locked.value,
            event.target.published.value,
            event.target.x.value,
            event.target.y.value
        );
        setIsUploading(false);
        setModal(false);
    };

    let mainImageOnChange = (event) => {
        event.preventDefault();
        setFile({...file, mainImage: event.target.files[0]});
    };

    let secondaryImageOnChange = (event) => {
        event.preventDefault();
        setFile({...file, secondaryImage: event.target.files[0]});
    };

    const updateDatabase = (e) => {
        e.preventDefault();
        setIsUploading(true);
        editorDispatch({type: ACTION.TOGGLE_SAVE_BTN, payload: true});
        //* update island db
        if (editorState.islandsToUpdate.length > 0) {
            console.log("updating islands");
            editorState.islandsToUpdate.map(async (island) => {
                // if (editorState.islandsToPublish[island.id]) {
                    console.log("opdaterer --> ", island);
                    //? Has imageURL been set set?
                    if (typeof island.imageURL == "object") {
                        const file = island.imageURL;
                        await firebase.uploadIsland(file, island.id);
                        island.imageURL = await firebase
                            .islandRef(file, island.id)
                            .getDownloadURL();
                    }
                    //? Has mapImageURL been set set?
                    else if (typeof island.mapImageURL == "object") {
                        const file = island.mapImageURL;
                        await firebase.uploadIsland(file, island.id);
                        island.mapImageURL = await firebase
                            .islandRef(file, island.id)
                            .getDownloadURL();
                    }
                    await firebase.updateIsland(island.id, 'islands_staging', island);
                // }
            });
            editorDispatch({type: ACTION.UPDATE_EMPTY_ISLAND_LIST});
            editorDispatch({type: ACTION.TOGGLE_PUBLISH_BTN, payload: false});
        }

        //* update character db
        if (editorState.charactersToUpdate.length > 0) {
            console.log("opdater karakterliste");
            editorState.charactersToUpdate.map(async (character) => {
                if (localStorage.getItem(character.id) == "true") {
                    console.log(character)
                    if (typeof character.imageURL == "object") {
                        const file = character.imageURL;
                        await firebase.uploadElement(file, location);
                        character.imageURL = await firebase
                            .elementRef(file, location)
                            .getDownloadURL();
                    }

                    if (typeof character.audioURL == "object") {
                        const file = character.audioURL;
                        await firebase.uploadElement(file, location);
                        character.audioURL = await firebase
                            .elementRef(file, location)
                            .getDownloadURL();
                    }
                    await firebase.updateElement(
                        character.id,
                        "islands_staging",
                        location,
                        character
                    );
                }
            });
            editorDispatch({type: ACTION.UPDATE_EMPTY_CHARACTER_LIST});
            editorDispatch({type: ACTION.TOGGLE_PUBLISH_BTN, payload: false});
        }
        setTimeout(() => {
            setIsUploading(false);
        }, 1000);
    };

    function DBElement(element, type) {
        console.log(element.name)
        let elements = editorState.islandsToPublish
        let amIchecked = elements.find(el => el === element.id)
        return (
            <li key={element.name}>
                <input type="checkbox" defaultChecked={amIchecked ? true : false}
                       onInput={
                           e =>
                               editorDispatch({
                                   type: ACTION.PUBLISH_TOGGLE_ELEMENTS,
                                   payload: {
                                       name: element.id,
                                       type: type,
                                       bool: e.currentTarget.checked}})}/>
                <img
                    className="dbElement"
                    name={element.name}
                    src={element.imageURL}
                    alt=""
                    width="100px"
                    height="auto"
                    onClick={() => {
                        document.dispatchEvent(
                            new CustomEvent("moveFrame", {
                                detail: [element.x, element.y],
                            })
                        );
                        setElementInFocus(element);
                    }}/>
                <label htmlFor="DBelement">{element.name}</label>
            </li>);
    }

    function addOrRemove(element, type, boolean) {
        let elements = JSON.parse(localStorage.getItem(type)) || []
        if (boolean) {
            elements.push(element.id)
            localStorage.setItem(type, JSON.stringify(elements))
        } else {
            // fjern fra liste hvis unchecked
            let newElements = elements.filter(el => el !== element.id)
            localStorage.setItem(type, JSON.stringify(newElements))
        }
    }

    return (
        <section className="shelf-container">
            <div className="shelf-container-inner">
                <div className="updateContainer">
                    <Loader isUploading={isUploading}/>
                    <div className="saveChangesButtonContainer">
                        <input
                            type="submit"
                            id="saveChangesButton"
                            value="Gem kladde"
                            disabled={editorState.saveChangesBtn}
                            onClick={updateDatabase}
                        />
                        <input
                            type="submit"
                            id="publishButton"
                            value="Offentliggør"
                            disabled={editorState.publishChangesBtn}
                            onClick={() => {
                                var answer = window.confirm(
                                    "Er du sikker? Dette vil offentliggøre dine ændringer."
                                );
                                if (answer) {
                                    publish(firebase, location, setIsUploading);
                                }
                            }}
                        />

                    </div>
                    {matchExact(editorState.currentPage, "/") ? (
                        <>
                            {modal && (
                                <IslandModal
                                    onUploadIsland={onUploadIsland}
                                    mainImageOnChange={mainImageOnChange}
                                    secondaryImageOnChange={secondaryImageOnChange}
                                    setModal={setModal}
                                    isUploading={isUploading}
                                />
                            )}
                            <h1>ø-oversigt</h1>
                            <ul className="DBElements">
                                <li>
                                    <input
                                        type="submit"
                                        className="plusButton"
                                        name="button"
                                        value="+"
                                        onClick={() => {
                                            setModal(true);
                                        }}
                                    />
                                </li>
                                {islands.map(island => DBElement(island, 'islands'))}
                            </ul>
                        </>
                    ) : (
                        <>
                            <UpdateWindow
                                elementInFocus={elementInFocus}
                                setElementInFocus={setElementInFocus}
                                setIsUploading={setIsUploading}
                                isUploading={isUploading}
                                updateDatabase={updateDatabase}
                            />
                            {modal && (
                                <ElementsModal
                                    elements={elements}
                                    onUploadElement={onUploadElement}
                                    mainImageOnChange={mainImageOnChange}
                                    setModal={setModal}
                                    file={file}
                                    setFile={setFile}
                                    isUploading={isUploading}
                                />
                            )}

                            <h1>ø-elementer</h1>
                            <ul className="DBElements">
                                <li>
                                    <input
                                        type="submit"
                                        className="plusButton"
                                        name="button"
                                        value="+"
                                        onClick={() => {
                                            setModal(true);
                                        }}
                                    />
                                </li>
                                {elements.map(element => DBElement(element, 'characters'))}
                            </ul>
                            <input
                                type="submit"
                                id="revertButton"
                                value="Drop kladde"
                                onClick={() => {
                                    var answer = window.confirm(
                                        "Er du sikker? Dette vil slette kladden og øen vil ligne den offenliggjorte!"
                                    );
                                    if (answer) {
                                        revert(firebase, location, setIsUploading);
                                    }
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}

async function publish(firebase, location, setIsUploading) {
    const {editorState, editorDispatch} = useContext(editorContext);
    setIsUploading(true);
    const publishIsland = firebase.functions.httpsCallable("publishIsland");
    const publishElements = firebase.functions.httpsCallable("publishElements");

    let checkedIslandsToUpdate = editorState.islandsToPublish
    if(location !== "/") {
        checkedIslandsToUpdate = [location]
    }
    checkedIslandsToUpdate.forEach( island => {
        publishIsland(island)
            .then(() => {
                console.log(island + " published");
            })
            .catch((error) => console.error(error));

        publishElements(island)
            .then(() => {
                console.log(island + " elements published");
            })
            .catch((error) => {
                console.error(error)
                setIsUploading(false);
            });
    })
    setIsUploading(false);
}

async function revert(firebase, location, setIsUploading) {
    const {editorState, editorDispatch} = useContext(editorContext);
    setIsUploading(true);
    const revertIsland = firebase.functions.httpsCallable("revertIsland");
    const revertElements = firebase.functions.httpsCallable("revertElements");

    await revertIsland(location)
        .then((res) => {
            console.log(location + " reverted");
        })
        .catch((error) => console.error(error));
    await revertElements(location)
        .then((res) => {
            console.log(location + " elements reverted");
        })
        .catch((error) => {
            console.error(error)
            setIsUploading(false);
        });
    setIsUploading(false);
}


function Loader(props) {
    if (props.isUploading) {
        return (
            <div className="uploading-progress">
                <img src={Spinner} alt="Uploader"/>
            </div>
        );
    } else {
        return null;
    }
}

const matchExact = (r, str) => {
    var match = str.match(r);
    return match && str === match[0];
};