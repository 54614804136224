class Reepay{
    async getCustomer(handle) {
        return await fetch('https://droem-hn4sec6b7a-lz.a.run.app/reepay/customer?handle='+handle,
        {
            method: 'GET',
          
        }).then(response => response.json())
        .catch(error => error.json())
    
    }

    async updateCustomer(handle, newUserDetails) {
        return await fetch('https://droem-hn4sec6b7a-lz.a.run.app/reepay/customer?handle='+handle,
        {
            method: 'POST',
            body: JSON.stringify(
                newUserDetails
             )
        }).then(response => {return 1})
        .catch(error => {return -1})
    
    }
}

export const reepay = new Reepay()
export default Reepay
