export const initialState={
    renderEditor: false,
    saveChangesBtn: true,
    publishChangesBtn: true,
    islandsToUpdate: [],
    charactersToUpdate: JSON.parse(localStorage.getItem("characters")) || [],
    islandsToPublish: JSON.parse(localStorage.getItem("islands")) || [],
    charactersToPublish: [],
    currentPage:'/'
}

export const ACTION = {
    UPDATE_ADD_ISLAND: "add-island",
    UPDATE_ADD_CHARACTER: "add-character",
    UPDATE_EMPTY_ISLAND_LIST: "empty-island",
    UPDATE_EMPTY_CHARACTER_LIST: "empty-character",
    PUBLISH_TOGGLE_ELEMENTS: "publish-toggle-elements",
    PUBLISH_EMPTY_ISLAND_LIST: "publish-empty-island",
    PUBLISH_EMPTY_CHARACTER_LIST: "publish-empty-character",

    TOGGLE_EDITOR: "toggle-editor",
    TOGGLE_SAVE_BTN: "toggle-save-button",
    TOGGLE_PUBLISH_BTN: "toggle-publish-button",
    CURRENT_PAGE:'current-page'
}

export const reducer = (state, action) =>{
    switch(action.type){
        case ACTION.UPDATE_ADD_ISLAND:
           return {...state, islandsToUpdate: [...state.islandsToUpdate.filter(island => island.id !== action.payload.island.id), action.payload.island]}
        case ACTION.PUBLISH_TOGGLE_ELEMENTS:
            return addOrRemove(action.payload.name,  action.payload.type, action.payload.bool);
        case ACTION.UPDATE_EMPTY_ISLAND_LIST:
            return {...state, islandsToUpdate:[]}
        case ACTION.UPDATE_ADD_CHARACTER:
            console.log('characterarray updated')
            return {...state, charactersToUpdate: [...state.charactersToUpdate.filter(character => character.id != action.payload.character.id), action.payload.character]}
        case ACTION.UPDATE_EMPTY_CHARACTER_LIST:
            return {...state, charactersToUpdate:[]}
        case ACTION.TOGGLE_EDITOR:
            return {...state, renderEditor: !state.renderEditor}
        case ACTION.TOGGLE_SAVE_BTN:
            return {...state, saveChangesBtn: action.payload}
        case ACTION.TOGGLE_PUBLISH_BTN:
            return {...state, publishChangesBtn: action.payload}
        case ACTION.CURRENT_PAGE:
            return{...state, currentPage: action.payload}
        default: return null
    }
}

function addOrRemove(element, type, add) {
    let elements = JSON.parse(localStorage.getItem(type)) || []
    if (add) {
        elements.push(element)
        localStorage.setItem(type, JSON.stringify(elements))
        return true
    } else {
        // fjern fra liste hvis unchecked
        let newElements = elements.filter(el => el !== element)
        localStorage.setItem(type, JSON.stringify(newElements))
        return false
    }
}
