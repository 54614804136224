import React from "react";

export function IslandModal(props) {
  return (
    <section className="upload-modal">
      <div className="upload-container">
        <span>
          {" "}
          <h3> Upload ny ø</h3>{" "}
          <button onClick={() => props.setModal(false)}>Luk</button>
        </span>
        <div>
          Her kan du uploade en ny ø.
          <br />
          Giv den et navn og vælg dens billede
        </div>

        <form id="uploader" onSubmit={(e) => props.onUploadIsland(e)}>
          {/* <label htmlFor="file"></label> */}
          <input type="text" id="name" name="name" placeholder="Navngiv ø" />
          <label htmlFor="file">Ø-billede (det lille) </label>
          <input
            type="file"
            id="fileBtnMain"
            name="mainFile"
            onChange={(e) => props.mainImageOnChange(e)}
            placeholder="Upload billede"
          />
          <label htmlFor="file">Ø-kortbillede (det store) </label>
          <input
            type="file"
            id="fileBtnSecondary"
            name="secondaryFile"
            onChange={(e) => props.secondaryImageOnChange(e)}
            placeholder="Upload billede"
          />
          <label htmlFor="publish">Offentligør på side</label>
          <input type="checkbox" id="publish" name="published" defaultChecked />
          <label htmlFor="lock">Lås</label>
          <input type="checkbox" id="lock" name="locked" />
          <input type="text" id="xposition" name="x" placeholder="x-position" />
          <input type="text" id="yposition" name="y" placeholder="y-position" />
          <input type="submit" name="button" value="upload" />
        </form>
      </div>
    </section>
  );
}

export function ElementsModal(props) {
  return (
    <section className="upload-modal">
      <div className="upload-container">
        <button onClick={() => props.setModal(false)}>Luk</button>
        <form id="uploader" onSubmit={(e) => props.onUploadElement(e)}>
          <label htmlFor="imgfile">Billede</label>
          <input
            type="file"
            id="fileBtn"
            name="imgfile"
            onChange={(e) =>
              props.setFile({ ...props.file, mainImage: e.target.files[0] })
            }
          />
          <label htmlFor="audiofile">Lydfil</label>
          <input
            type="file"
            id="fileBtn"
            name="audiofile"
            onChange={(e) =>
              props.setFile({ ...props.file, mainAudio: e.target.files[0] })
            }
          />
          <input type="text" id="name" name="name" placeholder="name" />
          <label htmlFor="publish">Offentligør på side</label>
          <input type="checkbox" id="publish" name="published" defaultChecked />
          <label htmlFor="lock">Lås</label>
          <input type="checkbox" id="lock" name="locked" />
          <input type="text" id="xposition" name="x" placeholder="x-position" />
          <input type="text" id="yposition" name="y" placeholder="y-position" />
          <input type="submit" name="button" value="Upload" />
        </form>
      </div>
    </section>
  );
}
