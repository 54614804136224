import React, {
  useEffect,
  useRef,
  useState,
  useReducer,
  useContext,
} from "react";
import { ArrowBack } from "@material-ui/icons";
import { FirebaseContext } from "../Firebase";
import { editorContext } from "../../App";
import { ACTION } from "./reducer";

export const UpdateWindow = (props) => {
  const {editorState, editorDispatch} = useContext(editorContext);
  const [files, setFiles] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {main: null, second: null}
  );

  const [saveChangesButtonState, setSaveChangesButtonState] = useState(true);
  const firebase = useContext(FirebaseContext);
  const [data, setData] = useState({});

  const [elementData, setElementData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        id: "",
        name: "",
        imageURL: "",
        audioURL: "",
        x: "",
        y: "",
        locked: "",
        published: "",
        audioFileName: ""
      }
  );

  useEffect(() => {
    firebase.getDocument(editorState.currentPage, "islands_staging", (data) => {
          setData({
            id: data.id,
            name: data.name,
            islandURL: data.imageURL,
            mapURL: data.mapImageURL,
            locked: data.locked,
            published: data.published,
          });
        });
  }, [editorState.currentPage, firebase]);

  useEffect(() => {
    setElementData({
      id: props.elementInFocus["id"],
      name: props.elementInFocus["name"],
      imageURL: props.elementInFocus["imageURL"],
      audioURL: props.elementInFocus["audioURL"],
      x: props.elementInFocus["x"],
      y: props.elementInFocus["y"],
      locked: props.elementInFocus["locked"],
      published: props.elementInFocus["published"],
      audioFileName: props.elementInFocus["audioFileName"],
      imageFileName: props.elementInFocus["imageFileName"],
    });
  }, [props.elementInFocus, props.saveChangesButtonState]);

  return (
      <>
        {props.elementInFocus["name"] === undefined ? (
            <IslandDetails
                data={data}
                setData={setData}
                setFiles={setFiles}
                files={files}
                saveChangesButtonState={saveChangesButtonState}
                setSaveChangesButtonState={setSaveChangesButtonState}
                setIsUploading={props.setIsUploading}
            />
        ) : (
            <>
              <ArrowBack onClick={() => props.setElementInFocus({})}/>
              <ElementDetails
                  data={data}
                  elementData={elementData}
                  setElementData={setElementData}
                  setData={setData}
                  setFiles={setFiles}
                  files={files}
                  saveChangesButtonState={saveChangesButtonState}
                  setSaveChangesButtonState={setSaveChangesButtonState}
                  setIsUploading={props.setIsUploading}
              />
            </>
        )}
      </>
  );

  function IslandDetails(props) {

    const inputRefMap = useRef(null);
    const inputRefMain = useRef(null);
    const islandNameRef = useRef(null);

    const onFormChange = (event) => {
      event.preventDefault();
      editorDispatch({type: ACTION.TOGGLE_SAVE_BTN, payload: false});
      editorDispatch({type: ACTION.TOGGLE_PUBLISH_BTN, payload: true});
      // eslint-disable-next-line default-case
      switch (event.target.name) {
        case "mainImage":
          props.setData({
            ...data,
            islandURL: URL.createObjectURL(event.target.files[0]),
          });
          editorDispatch({
            type: ACTION.UPDATE_ADD_ISLAND,
            payload: {
              island: {
                id: editorState.currentPage,
                imageURL: event.target.files[0],
                // name: islandName
              },
            },
          });

          break;
        case "mapImage":
          props.setData({
            ...data,
            mapURL: URL.createObjectURL(event.target.files[0]),
          });
          editorDispatch({
            type: ACTION.UPDATE_ADD_ISLAND,
            payload: {
              island: {
                id: editorState.currentPage,
                mapImageURL: event.target.files[0],
              },
            },
          });
          break;
        default:
          props.setData({
            ...data,
            name: event.target.value,
          });
          editorDispatch({
            type: ACTION.UPDATE_ADD_ISLAND,
            payload: {
              island: {
                id: editorState.currentPage,
                name: event.target.value
              },
            },
          });
          break;
      }
    };

    return (
      <div className={"updateContainer"}>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            name="islandName"
            id="currentIslandName"
            defaultValue={props.data.name}
            ref={islandNameRef}
            onBlur={onFormChange}

          />

            <span className="islandUpdateInnerContainer">
            <div className="islandUpdateCheckboxes">
              <label htmlFor="publish">Offentligør på side</label>
              <input
                  type="checkbox"
                  id="publish"
                  name="published"
                  onChange={onFormChange}
              />
              <label htmlFor="lock">Lås</label>
              <input type="checkbox" id="lock" name="locked" onChange={onFormChange}/>
            </div>

            <input
                type="file"
                className="imgupload"
                name="mainImage"
                style={{display: "none"}}
                ref={inputRefMain}
                accepts="image/*"
                onChange={onFormChange}
            />
            <button
                type="button"
                className="islandUpdateButton"
                id="mainImageUpload"
                onClick={(e) => {
                  e.preventDefault();
                  inputRefMain.current.click();
                }}
            >
              <img
                  className="islandElementImage"
                  name="DBMainImage"
                  src={props.data.islandURL}
                  alt=""
                  width="100px"
                  height="auto"
              />
            </button>
            <input
                type="file"
                className="imgupload"
                name="mapImage"
                style={{display: "none"}}
                ref={inputRefMap}
                accepts="image/*"
                onChange={onFormChange}
            />
            <button
                type="button"
                className="islandUpdateButton"
                id="mapImageUpload"
                onClick={(e) => {
                  e.preventDefault();
                  inputRefMap.current.click();
                }}
            >
              <img
                  className="islandElementImage"
                  name="DBMapImage"
                  src={props.data.mapURL}
                  alt=""
                  width="100px"
                  height="auto"
              />
            </button>
          </span>
          </form>
        </div>
    );
  }


  function ElementDetails(props) {
    const inputRefAudio = useRef(null);
    const inputRefMain = useRef(null);

    const onFormChange = (event) => {
      event.preventDefault();

      editorDispatch({type: ACTION.TOGGLE_SAVE_BTN, payload: false});
      editorDispatch({type: ACTION.TOGGLE_PUBLISH_BTN, payload: true});
      // eslint-disable-next-line default-case
      switch (event.target.name) {
        case "mainImage":
          console.log("mainImage");
          props.setElementData({
            imageURL: URL.createObjectURL(event.target.files[0]),
            imageFileName: event.target.files[0].name,
          });
          editorDispatch({
            type: ACTION.UPDATE_ADD_CHARACTER,
            payload: {
              character: {
                id: props.elementData.id,
                imageURL: event.target.files[0],
              },
            },
          });
          break;

        case "audio":
          props.setElementData({
            audioURL: URL.createObjectURL(event.target.files[0]),
            audioFileName: event.target.files[0].name,
          });
          editorDispatch({
            type: ACTION.UPDATE_ADD_CHARACTER,
            payload: {
              character: {
                id: props.elementData.id,
                audioURL: event.target.files[0],
              },
            },
          });
          break;

        case "published":
          props.setElementData({published: !props.elementData.published});
          break;

        case "locked":
          props.setElementData({locked: !props.elementData.locked});
          break;

        case "elementName":
          props.setElementData({
            ...data,
            name: event.target.value,
          });

          editorDispatch({
            type: ACTION.UPDATE_ADD_CHARACTER,
            payload: {
              character: {
                id: props.elementData.id,
                name: event.target.value
              },
            },
          });
          break;
      }

      editorDispatch({type: ACTION.TOGGLE_SAVE_BTN, payload: false});
    };

    return (
        <div className="updateContainer">
          <form onSubmit={(e) => e.preventDefault()} onBlur={onFormChange}>
            <input
                name="elementName"
                id="currentElementName"
                defaultValue={props.elementData.name}
                onBlur={onFormChange}
            />
            <span className="islandUpdateInnerContainer">
            <div className="islandUpdateCheckboxes">
              <label htmlFor="publish">Offentligør på side</label>
              <input
                  type="checkbox"
                  id="publish"
                  name="published"
                  defaultChecked={props.elementData.published}
              />
              <label htmlFor="lock">Lås</label>
              <input
                  type="checkbox"
                  id="lock"
                  name="locked"
                  defaultChecked={props.elementData.locked}
              />
            </div>
            <input
                type="file"
                className="imgupload"
                name="mainImage"
                style={{display: "none"}}
                ref={inputRefMain}
                accepts="image/*"
                onChange={onFormChange}
            />
            <button
                type="button"
                className="islandUpdateButton"
                id="mainImageUpload"
                onClick={() => inputRefMain.current.click()}
            >
              <img
                  className="islandElementImage"
                  name="DBMainImage"
                  src={elementData.imageURL}
                  alt=""
                  width="100px"
                  height="auto"
              />
            </button>
            <input
                type="file"
                className="audioupload"
                name="audio"
                style={{display: "none"}}
                ref={inputRefAudio}
                accepts="audio/*"
                onChange={onFormChange}
            />
            <button
                type="button"
                className="audioUpdateButton"
                id="audioUpload"
                onClick={() => inputRefAudio.current.click()}
            >
              {props.elementData.audioFileName || "Lydfil"}
            </button>
          </span>
          </form>
        </div>
    );
  }
}
