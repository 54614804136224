import React from 'react'

import Image0 from '../images/avatars/0.png'
import Image1 from '../images/avatars/1.png'
import Image2 from '../images/avatars/2.png'
import Image3 from '../images/avatars/3.png'

export default function ProfilBillede({index, closure}) {

    const images = {
        0: Image0,
        1: Image1,
        2: Image2,
        3: Image3,
    }

    return (
        <div className="profilbillede-container"
             onClick = { closure }>
            <img className='profilbillede' src={images[index] || images[0]}  draggable="false"/>
        </div>)
}
