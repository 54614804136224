import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Link, Route } from "react-router-dom";

import Favicon from "react-favicon"
import Icon from "./images/favicon2.png"
import Firebase, { FirebaseContext } from './components/Firebase';
import App from './App';

ReactDOM.render(
<FirebaseContext.Provider value={new Firebase()}>
    <Router>
        <Favicon url={Icon}/>
        <App />
    </Router>
</FirebaseContext.Provider>, 


document.getElementById("root"))
